.label {
  align-items: center !important;
  font-size: 11px !important;
}

.carousel {
  display: flex;
  gap: 1rem;
  overflow-x: auto;
  padding: 5px 0;
}

.box {
  display: flex;
  gap: 5px;
}

.video {
  width: 150px;
  height: 88px;
}

.box-checkbox {
  display: flex;
  gap: 5px;
  margin-top: 16px;
}

.filename {
  max-width: 100px;
  font-size: 11px;
  margin: 0;
  text-align: center;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
}

.custom-preview-pdf {
  width: 130px !important;
  height: 100px !important;
  object-fit: cover;

  :global {
    .react-pdf__Page__canvas {
      object-fit: contain;
      width: 100% !important;
      height: 100% !important;
    }
  }
}
