.card-icon-expand-div {
  cursor: pointer;
}

.card-icon-drag-div {
  float: right;
}

.card-icon-left {
  width: calc(100% - 20px);
}
