.no-data {
  width: 100%;
  margin: 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  box-sizing: border-box;

  span {
    text-align: center;
    width: 100%;
    padding: 0;
    box-sizing: border-box;
  }
}
