.container {
  width: calc(100% - 1rem);
  padding: 1rem;
  border-radius: 8px;
}

.column-alignment {
  align-items: center;
  justify-content: center;
  height: 48px;
}

.head-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-block: 5px;
  padding-left: 15px;
  padding-right: 15px;
  background: #e7e6e6;
  min-height: 50px;
  border-radius: 4px 4px 0 0;
}

.icon {
  margin-right: 0.5rem;
}

.info-text-card {
  display: flex;
  align-items: center;
  font-weight: bold !important;
  font-size: min(max(12px, 4vw), 15px) !important;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 0 !important;
}

.chip {
  width: 78px !important;
}

.toolbar-actions {
  margin-left: auto;
  display: flex;
  align-items: center;

  div + div,
  div + button,
  button + button {
    margin-left: 1rem;
  }
}

.button-refresh {
  border-radius: 3px !important;
  border: none !important;
}

.button-redirect {
  border: none !important;
  background: #e7e6e6 !important;

  :global {
    .k-button-text {
      margin-left: 0px;
      margin-right: -8px;
    }
  }
}

.button-redirect:hover {
  border: none !important;
  background: #e7e6e6 !important;
  color: black !important;
}

.button-new {
  border: none !important;
  background: #3d3c3ed5 !important;
  color: #ffffff !important;
}

.button-new:hover {
  border: none !important;
  background: #3d3c3ed5 !important;
}

.icon-large {
  font-size: '0.875em';
}

.status-dropdown {
  background-color: #ffffff !important;
  border-radius: 3px !important;

  :global {
    .k-button-rectangle {
      border: none;
    }
  }
}
