@import '../../../../variables';

.container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 200px;
}

.card {
  flex: 1 0 25%;
  flex-grow: 1;
}

.card-on {
  background-color: #c2dbff;
}

.dialog {
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
}
