.buttons-field {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.container {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.form-buttons {
}
