.app-bar {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.bar-title {
  margin-left: 15px;
  font-size: 14px !important;
}

.button-collapse {
  box-shadow: none !important;
}

.white-color {
  color: white !important;

  :global {
    .k-button {
      color: white !important;
    }
  }
}

.search-input {
  background: #5d5d5d !important;
}
