.image-scroll {
  width: 100%;
  object-fit: contain;
}

.video {
  width: 100%;
  height: 100%;
}

.pdf {
  width: 100%;
  height: 100%;
}

.list {
  overflow-x: auto;
  height: 100%;
  display: flex;
}
