.list {
  align-items: center;
  border-block: solid 1px rgb(215 215 215);
  display: flex;
  padding-inline: 10px;
}

.listView {
  width: 50%;
  margin-right: 1rem;
  height: 40vh;
  color: #a0a0a0 !important;
  border: solid 1px rgb(215 215 215) !important;
  padding-inline: 10px;
  float: left;
}

.margin {
  margin-left: 15px;
}

.div {
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.itemTitle {
  font-size: 14px;
  color: #5f6368;
  // padding-block: 5px;
  margin-bottom: 0;
}

.itemMessage {
  font-size: 12;
  color: #5f6368;
}
