.brass-multiselect {
  width: 100%;
  margin-top: 1em;
  :global {
    .k-label {
      top: 0px !important;
      transform: scale(0.75) !important;
    }
  }
}
