$text-chat: #3a3a3a;
$black-75: #000000ad;
$green: #70ad47;
$text-color: #6d6d6d;

.container {
  width: 100%;

  * {
    box-sizing: border-box;
  }

  .box {
    height: auto;

    .box-footer {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      border-bottom: 2px solid $text-chat;

      .box-footer-left {
        display: flex;
        height: 24px;
        align-items: center;

        .box-footer-actions-counts {
          line-height: 12px;
          font-size: clamp(8px, 0.8vw, 13px);
          color: $text-chat;
          font-size: 12px;
          font-weight: 500;
        }

        .box-icons {
          width: 25px;
          height: 25px;
        }
      }

      .box-footer-right {
        .button-collapse {
          border: none;

          span svg {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }

  .box-colapse {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow-y: auto;
    padding: 0 40px 10px 10px !important;
  }

  .input-block-modified {
    margin: 0;

    .input-block-modified-input {
      height: 34px;
      font-size: clamp(6px, 0.8vw, 13px);
    }

    .input-block-modified-button {
      height: 34px;
    }
  }
}

.chat-container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;

  * {
    box-sizing: border-box;
  }

  .chat-block-container {
    display: flex;
    width: 100%;
    background-color: #ffff;
    flex-direction: column;
    align-items: flex-end;

    .chat-date {
      width: 100%;
      margin: 0;
      line-height: 12px;
      font-size: clamp(8px, 0.8vw, 13px);
      color: $text-chat;
      text-align: center;
    }

    .chat-block {
      display: inline-block;
      background-color: #ffff;
      flex-direction: column;

      &:nth-child(2) {
        margin-top: 16px;
      }

      .chat-block-user {
        width: 100%;
        margin: 8px 0;
        font-weight: bold;
        font-size: clamp(8px, 0.8vw, 13px);
        line-height: 12px;
        color: $text-chat;
      }

      .chat-block-edit {
        width: 100%;
        display: flex;

        .chat-block-edit-input {
          width: calc(100% - 40px);
          min-width: 350px;
          height: 24px;
          border: none;
          border-bottom-left-radius: 4px;
          border-top-left-radius: 4px;
          background-color: rgba(255, 166, 0, 0.13);
          padding: 0 12px;
          font-size: clamp(6px, 0.8vw, 11px);
        }

        .chat-block-edit-button {
          width: 40px;
          height: 24px;
          border: none;
          background-color: $black-75;
          display: flex;
          justify-content: center;
          align-items: center;
          border-bottom-right-radius: 4px;
          border-top-right-radius: 4px;
        }
      }

      .chat-block-description {
        width: 100%;
        margin: 8px 0;
        font-size: clamp(11px, 0.8vw, 16px);
        line-height: 14px;
        color: $text-chat;
      }

      .chat-block-actions {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .chat-block-actions-like {
          line-height: 12px;
          font-size: clamp(8px, 0.8vw, 13px);
          color: $text-chat;
        }
      }
    }
  }

  .separator {
    position: absolute;
    box-sizing: border-box;
    width: 20%;
    right: 0;
    height: 1px;
    margin-top: 2px;
  }
}

.no-data {
  width: 100%;
  margin: 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  box-sizing: border-box;

  span {
    text-align: center;
    width: 100%;
    padding: 0;
    box-sizing: border-box;
  }
}

.icon-chat {
  width: 18px;
  height: 18px;
  color: black;
  fill: black;
  cursor: pointer;
}

.icon {
  width: 25px;
  height: 25px;
  color: $green;
  fill: $green;
  margin: 4px 4px;
  cursor: pointer;
}

.tooltip {
  display: flex;
}

.box-avatar {
  display: flex;
  margin-right: 15px;
}

.avatar {
  margin-left: -4px;
  color: #3a3a3a !important;
  border: 0.5px solid #9d9d9d;
  background-image: linear-gradient(43deg, #a7a7a7 0%, #b3afaf 46%, #dadada 100%);
}

.count {
  margin-right: 10px;
  color: #3a3a3a;
}
