.action-button {
  width: 100%;
}

.enter-button-loader {
  padding-right: 10px;
}

.fields {
  .k-textbox-container {
    margin: 8px 0;
  }
}

.form {
  height: 75vh;
  margin: 5px;
}
