@import '../../../../../variables';

.drawer-item {
  :global {
    svg {
      margin-right: 5px !important;
      margin-top: 3px !important;
    }
  }
}

.drawer-item-selected {
  color: $primaryBrassColor !important;
}

.drawer-item:hover,
.drawer-item:focus {
  color: inherit !important;
  background-color: inherit !important;
}
