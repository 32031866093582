.publish-icon-list {
  display: flex;
  width: 100%;
  gap: 1rem;
  align-items: center;

  .hide {
    display: none;
  }

  svg {
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
}

.label {
  align-items: center !important;
  font-size: 11px !important;
}

.icon {
  margin-right: 10px;
  display: flex;
  align-items: center;
  font-size: 14px !important;
}

.icon-publish {
  font-size: 25px !important;
}
