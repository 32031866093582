$green: #70ad47;
$yellow: #facc2c;
$orange: #fda451;
$red: #da0b0b;
$midgray: #3b4351;
$lightgray: #595959;
$darkgray: #2d2b2b;
$blue: #0070c0;
$purple: #7030a0;
$base: #6b6b6b;

.chip {
  background-color: #e7e6e6 !important;
  border-radius: 20px !important;
  font-size: 12px !important;
}

.color {
  &__green {
    color: $green !important;
  }

  &__yellow {
    color: $yellow !important;
  }

  &__orange {
    color: $orange !important;
  }

  &__red {
    color: $red !important;
  }

  &__midgray {
    color: $midgray !important;
  }

  &__lightgray {
    color: $lightgray !important;
  }

  &__darkgray {
    color: $darkgray !important;
  }

  &__blue {
    color: $blue !important;
  }

  &__purple {
    color: $blue !important;
  }

  &__base {
    color: $base !important;
  }
}
