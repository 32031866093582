@import '../../../../../variables';

.brass-grid-edit-button {
  :global {
    svg {
      color: rgba($primary, 0.6) !important;
      font-size: medium;
    }
  }
}

.brass-grid-edit-button:hover {
  background-color: rgba($primary, 0.1) !important;

  :global {
    svg {
      color: rgba($primary, 0.8) !important;
    }
  }
}
