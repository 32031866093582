.window {
  width: 94.7vw !important;
  margin: 10px !important;

  :global {
    .k-toolbar {
      background-color: #d7d7d7 !important;
    }

    .k-grid-header-wrap {
      background-color: #cbcbcb !important;
    }
  }
}
