.brass-listview {
  :global {
    .k-listview-content {
      display: flex !important;
      width: 100vw !important;
    }

    .k-listview-header {
      margin-top: 15px;
    }
  }
}
