.list {
  align-items: center;
  border: solid 1px #a0a0a0;
  display: flex;
  padding: 0 10px;
}

.displayCommunication {
  border: solid 1px rgb(215 215 215);
  width: 100%;
  height: 40vh;
  float: right;
}

.title {
  font-weight: bold;
  float: left;
  padding-block: 10px;
}

.body {
  margin-bottom: 15px;
}

.chat {
  max-width: unset !important;
  width: 100%;
  height: 40vh;
}

.footer {
  height: 40px;
}

.emptyViewer {
  text-align: center;
  color: rgb(160 160 160);
  font-size: 1.2rem;
  margin-top: 25px;
}
