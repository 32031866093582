.brass-multiselect {
  :global {
    .k-input-values.k-chip-list.k-selection-multiple.k-chip-list-md {
      padding: 0 !important;
      margin: 0 !important;

      .k-chip.k-chip-md.k-rounded-md.k-chip-solid.k-chip-solid-base {
        font-size: 13px;
        height: 20px;
        margin: 0 5px;
      }
    }
  }
}
