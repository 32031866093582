@import '../../../../../variables';

.brass-grid {
  height: calc(100vh - 98px) !important;
  margin: 10px !important;

  :global {
    .k-grid-toolbar {
      background-color: #e7e6e6 !important;
      border: none !important;
    }

    .brass-grid-cell {
      padding: 10px;
    }

    .k-column-title,
    .k-button-text {
      font-size: 11px;
    }

    .k-grid-header-wrap {
      padding-bottom: 7px !important;
    }

    .k-grid-header-wrap thead th {
      border-bottom: none;
    }

    .k-grid-header th.k-header {
      border-bottom: none !important;
      padding-bottom: 1px !important;
      padding-top: 1px !important;
    }

    .k-filter-row th {
      padding-bottom: 0 !important;
      padding-left: 3px !important;
      padding-right: 3px !important;
      padding-top: 0 !important;
    }

    .k-pager-wrap {
      padding: 0 !important;
    }

    .k-grid-pager .k-pager-info {
      margin-right: 35px;
    }

    .k-grid-container .k-grid-table tr.k-master-row td {
      font-size: 12px !important;
      padding: 3px;
    }

    .k-grouping-row td {
      padding-top: 1px;
      padding-bottom: 1px;
      background-color: #e1e1e1;
    }

    .k-grid-header .k-grid-filter,
    .k-grid-header .k-header-column-menu {
      z-index: auto;
    }

    .k-pager-sizes,
    .k-pager-numbers {
      z-index: 0 !important;
    }

    button[title='Clear'],
    .k-filtercell-operator {
      display: none !important;
    }
  }
}

.brass-grid-title {
  color: rgba($primary, 0.9) !important;
  margin: 0;
  margin-left: 10px;
  font-size: 16px !important;
  align-items: center;

  :global {
    svg {
      color: rgba($primary, 0.9) !important;
      margin: 0 5px 0 0;
    }
  }
}

.brass-grid-column {
  text-align: center !important;
  justify-items: center !important;
  align-items: center !important;
}

.brass-grid-user-help {
  margin: 10px;
}

.brass-tree-list {
  overflow: auto;
  max-height: calc(100vh - 100px);

  :hover {
    background-color: inherit !important;
  }
}

.brass-button-groupable,
.brass-button-export {
  background-color: #e7e6e6 !important;
  border: none !important;
  border-radius: 50% !important;
}
