.title {
  margin-inline: 5px;
  height: 3vh;
}

.children {
  margin-inline: 5px;
  margin-top: 10px;
  height: 65vh;
  overflow: auto;
}

.children-details-opened {
  margin-inline: 5px;
  margin-top: 10px;
  height: 40vh;
  overflow: auto;
}

.bottomButtons {
  position: absolute;
  right: 10;
  bottom: 1%;
}

.bottomButtonClose {
  margin-right: 20px;
}

.bottomButtonSave {
  margin-right: 5px;
}
