@import '../../../../variables';

$green: #70ad47;
$yellow: #facc2c;
$orange: #fda451;
$red: #da0b0b;
$midgray: #3b4351;
$lightgray: #595959;
$darkgray: #2d2b2b;

:root {
  --background-table: #fff;
  --color-table: #0000008a;
}

.brass-grid {
  height: calc(100vh - 80px);

  :global {
    .k-grid-toolbar {
      border: none !important;
    }

    .k-grid-header.k-grid-draggable-header {
      background-color: var(--background-table) !important;
      color: var(--color-table) !important;
    }

    .k-column-title {
      font-size: 11px;
    }

    .k-grid-header th.k-header {
      border-bottom: none !important;
      padding-bottom: 1px !important;
      padding-top: 1px !important;
    }

    .k-filter-row th {
      padding-bottom: 0 !important;
      padding-left: 3px !important;
      padding-right: 3px !important;
      padding-top: 0 !important;
    }

    .k-filtercell {
      padding: 0 12px 5px;
    }

    .k-filtercell .k-input {
      height: 30px !important;
      font-size: 12px !important;
    }

    .k-pager-wrap {
      padding: 0 !important;
    }

    .k-grid-pager .k-pager-info {
      margin-right: 35px;
    }

    .k-grid-container .k-grid-table tr.k-master-row td {
      padding: 3px 12px;
      font-size: 12px;
    }

    .k-grouping-row td {
      padding-top: 1px;
      padding-bottom: 1px;
      background-color: #e1e1e1;
    }

    .k-grid-header .k-grid-filter,
    .k-grid-header .k-header-column-menu {
      z-index: auto;
    }

    .k-pager-sizes,
    .k-pager-numbers {
      z-index: 0 !important;
    }

    button[title='Clear'],
    .k-filtercell-operator {
      display: none !important;
    }
  }
}

.brass-grid-title {
  color: rgba($primary, 0.9) !important;
  margin: 0;
  margin-left: 10px;

  :global {
    svg {
      color: rgba($primary, 0.9) !important;
      margin: 2px 5px 0 0;
    }
  }
}

.brass-grid-column {
  text-align: center !important;
  justify-items: center !important;
  align-items: center !important;
}

.brass-grid-brass-logo {
  bottom: 35px;
  float: right;
  margin-right: 3px;
  position: relative;
}

.brass-grid-user-help {
  margin: 10px;
}

.brass-button-groupable,
.brass-button-export {
  background-color: #e7e6e6 !important;
  border: none !important;
  border-radius: 50% !important;
}

.brass-tree-list {
  overflow: auto;
  max-height: calc(100vh - 100px);
}
