@import '../../../../variables';

.container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 300px;
}

.card {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-on {
  background-color: #c2dbff;
}
