@import '../../../variables';

.brass-dialog {
  :global {
    .k-overlay {
      visibility: hidden;
      background-color: $primary !important;
    }
  }
}

.brass-dialog-align-right {
  :global {
    .k-widget .k-dialog .k-window .k-dialog-wrapper {
      position: absolute !important;
      right: 0 !important;
    }
  }
}

.dialog-title-with-icon {
  align-items: baseline;
  display: flex;
}
