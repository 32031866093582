.carousel {
  height: 100%;
  display: flex;
  margin: 0 auto;
  width: 100%;
  gap: 8px;
  padding: 20px 0 20px 0px;
  position: relative;
  box-sizing: border-box;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  --webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  scrollbar-width: none;
}

.carousel-button-left {
  position: sticky;
  left: 0px;
  height: 100%;
  z-index: 1000000000;
  width: 20px;
  transition: all ease-in 500ms;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: gray;
  opacity: 0.1;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}

.carousel-button-right {
  position: sticky;
  right: 0;
  height: 100%;
  z-index: 1000000000;
  width: 20px;
  transition: all ease-in 500ms;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: gray;
  opacity: 0.1;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}
