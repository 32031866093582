.list {
  align-items: center;
  border: solid 1px #a0a0a0;
  display: flex;
  padding: 0 10px;
}

.displayCommunication {
  border: solid 1px rgb(215 215 215);
  height: 40vh;
  float: right;
  width: 50%;
  margin-left: 1rem;
  padding-inline: 10px;
}

.title {
  font-weight: bold;
  float: left;
  padding-block: 10px;
}

.body {
  height: calc(40vh - 60px);
  overflow: scroll;
  width: 100%;
  margin-bottom: 15px;
}

.footer {
  height: 40px;
}

.emptyViewer {
  text-align: center;
  color: rgb(160 160 160);
  font-size: 1.2rem;
  margin-top: 25px;
}

.internDiv {
  font-family: 'DejaVu Sans', 'Arial', sans-serif;
  font-size: 12px;

  img {
    width: 100%;
  }
}

.iframetap {
  border: 0px;
  width: 100%;
  height: 100vh;
  overflow: scroll;
}

@font-face {
  font-family: 'DejaVu Sans';
  src: url('https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans.ttf')
    format('truetype');
}

@font-face {
  font-family: 'DejaVu Sans';
  font-weight: bold;
  src: url('https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Bold.ttf')
    format('truetype');
}

@font-face {
  font-family: 'DejaVu Sans';
  font-style: italic;
  src: url('https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf')
    format('truetype');
}

@font-face {
  font-family: 'DejaVu Sans';
  font-weight: bold;
  font-style: italic;
  src: url('https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf')
    format('truetype');
}
