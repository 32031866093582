.project-name-group {
  display: flex;
  margin-left: 10px;

  :global {
    .brass-icon {
      margin-right: 5px;
    }
  }
}

.project-name-secundary {
  font-size: x-small;
  line-height: normal;
}

.icon-navigator {
  margin-left: 5px;
}
