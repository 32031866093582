.dialog > div > .k-widget .k-dialog .k-window .k-dialog-wrapper {
  position: absolute !important;
  right: 0 !important;
  top: 0 !important;

  :global {
    .k-widget .k-dialog .k-window .k-dialog-wrapper {
      position: absolute !important;
      right: 0 !important;
    }
  }
}
