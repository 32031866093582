$text-color: #3a3a3a;
$orange: #f6873c;
$orange-75: #f6863cad;
$green: #70ad47;

.chat-container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;

  * {
    box-sizing: border-box;
  }

  .chat-block-container {
    display: flex;
    width: 100%;
    background-color: #ffff;
    flex-direction: column;
    align-items: flex-end;

    .chat-date {
      width: 100%;
      margin: 0;
      line-height: 12px;
      font-size: clamp(8px, 0.8vw, 13px);
      color: $text-color;
      text-align: center;
    }

    .chat-block {
      display: inline-block;
      background-color: #ffff;
      flex-direction: column;

      &:nth-child(2) {
        margin-top: 16px;
      }

      .chat-block-user {
        width: 100%;
        margin: 8px 0;
        font-weight: bold;
        font-size: clamp(8px, 0.8vw, 13px);
        line-height: 12px;
        color: $text-color;
      }

      .chat-block-edit {
        width: 100%;
        display: flex;

        .chat-block-edit-input {
          width: calc(100% - 40px);
          min-width: 350px;
          height: 24px;
          border: none;
          border-bottom-left-radius: 4px;
          border-top-left-radius: 4px;
          background-color: rgba(255, 166, 0, 0.13);
          padding: 0 12px;
          font-size: clamp(6px, 0.8vw, 11px);
        }

        .chat-block-edit-button {
          width: 40px;
          height: 24px;
          border: none;
          background-color: $orange-75;
          display: flex;
          justify-content: center;
          align-items: center;
          border-bottom-right-radius: 4px;
          border-top-right-radius: 4px;
        }
      }

      .chat-block-description {
        width: 100%;
        margin: 8px 0;
        font-size: clamp(11px, 0.8vw, 16px);
        line-height: 14px;
        color: $text-color;
      }

      .chat-block-actions {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .chat-block-actions-like {
          line-height: 12px;
          font-size: clamp(8px, 0.8vw, 13px);
          color: $text-color;
        }
      }
    }
  }

  .separator {
    position: absolute;
    box-sizing: border-box;
    width: 20%;
    right: 0;
    height: 2px;
    margin-top: 2px;
    background-color: $orange;
  }
}

.no-data {
  width: 100%;
  margin: 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  box-sizing: border-box;

  span {
    text-align: center;
    width: 100%;
    padding: 0;
    box-sizing: border-box;
  }
}

.icon {
  width: 20px;
  height: 20px;
  color: $green;
  fill: $green;
  margin: 4px 4px;
  cursor: pointer;
}
