.item {
  gap: 2.5rem;
  display: flex;
  padding-block: 1rem;
}

.box-editor {
  width: 100%;

  h1 {
    margin: 4px 0 1px !important;
  }

  h2 {
    margin: 4px 0 1px !important;
  }

  h3 {
    margin: 4px 0 1px !important;
  }

  h4 {
    margin: 4px 0 1px !important;
  }
}

.actions {
  display: flex;

  svg {
    font-size: 18px;
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
}

.button {
  span {
    display: flex;
  }
}

.text {
  display: flex;

  p {
    margin: 0;
    font-size: 16px;
  }
}

.index {
  font-size: 18px;
  font-weight: bold;
}

.selected {
  background: #e9e9e97c;
}

.toolbar {
  margin: 0 !important;
  background: #fff;
  padding: 0 !important;
  border: 0 !important;
}

.toolbar-sticky {
  position: sticky !important;
  top: 0 !important;
}

.wrapper1 {
  width: 100%;
}
