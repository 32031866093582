.box {
  display: grid;
  gap: 2%;
  grid-template-columns: 2fr 2fr;
}

.icon {
  margin-right: 5px;
}

.button-redirect {
  background-color: #513e2a !important;
}

.button-general {
  font-size: 12px !important;
  padding: 5px 10px !important;
  border: none !important;
  height: 30px !important;

  svg {
    font-size: 14px !important;
    height: 17px !important;
  }
}
