.container-image-dotted {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-size: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 12px 0;
  flex-direction: column;
}

.image-preview {
  width: 95%;
  height: 95%;
  padding: 8%;
}

.external-drop-zone {
  width: 95% !important;
  height: 80% !important;

  div {
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-clip: content-box !important;
    background-size: contain !important;
  }
}

.external-drop-zone.is-image {
  span {
    display: none !important;
  }
}

.external-drop-zone-multiple {
  width: 95%;
  height: 80%;
  margin: auto;
}

.k-dropzone {
  display: flex !important;
}

.upload-button {
  border: none !important;
  width: 50%;
  padding: 0;
  margin: 0;

  div div + div {
    display: none !important;
  }
}

.container-select-save {
  display: flex;
  height: 20%;
  width: 96%;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
}

:global {
  .k-upload-button {
    box-shadow: none;
    background-color: #ebebeb;
    height: 30px;
    padding: 5px 10px;
    font-size: 14px !important;
  }

  .k-upload-files.k-reset {
    height: 82%;
  }

  @media (max-height: 800px) {
    .k-upload-files.k-reset {
      height: 65% !important;
    }
  }
}
