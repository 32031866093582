.panel-view-flag {
  background-color: #f5f5f5;
  display: flex;
  justify-content: flex-;
  align-items: center;
  gap: 2px;
  padding: 10px 10px;
  flex-wrap: wrap;
  min-height: 2rem !important;
  overflow-y: auto !important;
  max-height: 6rem !important;
}

.chip {
  background-color: #e7e6e6 !important;
  color: #6b6b6b !important;
  border-radius: 20px !important;
  padding: 1px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 12px;
}

.label {
  font-size: 11px !important;
}
