.main-document {
  float: right;
  opacity: 0.6;
  right: 45px;
  text-align: right;
}

.empty-viewer {
  float: right;
  position: absolute;
  bottom: 70px;
  right: 50px;
  text-align-last: center;
}
