.content {
  margin-bottom: 1rem;
}

.box2 {
  display: flex;
  width: 100%;
  gap: 10px;
  margin-top: 1rem;
}

.button-save {
  font-size: 12px !important;
  padding: 5px 10px !important;
  border: none !important;
  height: 30px !important;

  svg {
    font-size: 14px !important;
  }
}
