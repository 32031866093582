.container-publish {
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  width: 32%;
  padding: 10px;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  box-sizing: border-box;

  .publish-title {
    display: flex;
    height: 5%;
    min-height: 22px;

    .title {
      font-size: 14px;
      font-weight: 500;
      display: flex;
      align-items: center;

      .icon {
        font-size: 14px;
        margin-right: 10px;
      }
    }
  }

  .publish-icon-list {
    display: flex;
    width: 100%;
    height: 10%;
    justify-content: space-evenly;
    align-items: center;
    margin: 16px 0;

    svg {
      width: 25px;
      height: 25px;
      cursor: pointer;
    }
  }

  .publish-main {
    height: 77%;

    .publish-main-content {
      width: 100%;
      height: 100%;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' style='stroke: orange;' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='10' stroke-linecap='square'/%3e%3c/svg%3e");
    }
  }
}

@media (max-height: 800px) {
  .publish-main {
    height: 70% !important;
  }
}
