.list {
  align-items: center;
  border-block: solid 1px rgb(215 215 215);
  display: flex;
  padding-inline: 10px;
}

.itemTitle {
  font-size: 14px;
  color: #5f6368;
  margin-bottom: 0;
}

.itemMessage {
  font-size: 12;
  color: #5f6368;
}

.margin {
  margin-left: 15px;
}

.div {
  padding: 0 10px;
}
