.container-image-dotted {
  width: 100%;
  height: 80%;
  // border: 5px dashed black;
  background-size: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='black' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.image-preview {
  width: 95%;
  height: 95%;
  padding: 8%;
}

.external-drop-zone {
  width: 95% !important;
  height: 95% !important;
  padding: 8% 2.5% !important;

  div {
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-clip: content-box !important;
    background-size: contain !important;
  }

  // background-size: cover !important;
}

.external-drop-zone.is-image {
  span {
    display: none !important;
  }
}

.k-dropzone {
  display: flex !important;
}

.upload-button {
  border: none !important;
  width: 50%;
  padding: 0;
  margin: 0;

  div div + div {
    display: none !important;
  }
}

.container-select-save {
  display: flex;
  height: 20%;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
}

.button-save-image {
  font-size: 12px !important;
  padding: 5px 10px !important;
  border: none !important;
  height: 30px !important;

  svg {
    font-size: 14px !important;
  }
}
