$green: #70ad47;
$yellow: #facc2c;
$orange: #fda451;
$red: #da0b0b;
$midgray: #3b4351;
$lightgray: #595959;
$darkgray: #2d2b2b;

.brass-tooltip-custom {
  position: relative;
  max-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tooltip-icon {
  font-size: 16px;
  cursor: pointer;
}

.tooltip-body {
  position: absolute;
  max-height: 700px;
  overflow: overlay;
  background-color: #a89e94;
  padding: 10px;
  z-index: 10002;
  max-width: 400px !important;
}

.tooltip-body-bar {
  background: #a89e94;
}
