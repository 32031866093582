.enter-button {
  margin: 20px 0;
  width: 100%;
}

.enter-button-loader {
  padding-right: 10px;
}

.cancel-button {
  width: 100%;
}

.tabContainer {
  margin-top: 10px;

  li:last-child {
    margin-left: auto !important;
  }
}

.tabContent {
  padding: 0 !important;
}
