@import '../../../../../variables';

.div-messages-info {
  color: rgba($primary, 0.5);
  font-size: 12px;
  font-style: normal;
  text-align: end;
  width: 100%;
}

.icon {
  margin-right: 10px;
  display: flex;
  align-items: center;
  font-size: 14px !important;
  width: 14px !important;
}

.label {
  align-items: center !important;
  font-size: 11px !important;
}

.input {
  background: #dcd8d4 !important;
  height: 30px !important;
  font-size: 12px !important;
  padding: 8px 10px !important;

  &:disabled {
    background-color: #cccccc !important;
    pointer-events: none;
    border-bottom: 1px dashed #929292;
    color: #00000087;
  }
}
