.group {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  margin: 1rem;
  background-color: #d6d6d6;
  border-radius: 8px;
  box-shadow: 6px 6px 20px rgba(117, 117, 117, 0.25);
}

.input-group {
  display: flex;
  width: 90%;
  justify-content: space-between;
  gap: 5px;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.layers {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 2rem 2rem 1rem;
}

.content + .content {
  margin-left: 2.5rem;
}

.footer {
  display: flex;
  justify-content: flex-end;
  margin: 1rem 1rem;
}

.button {
  background: #746555 !important;
  border: none !important;
  font-size: 12px !important;
  padding: 5px 10px !important;
  border: none !important;
  height: 30px !important;

  svg {
    font-size: 14px !important;
  }
}

.exhibition {
  display: flex;
  padding: 0rem 0rem 2rem;
}

.exhibition-item {
  font-size: 1.2rem;
  color: #513e2a;

  &__disabled {
    color: #9c9c9c;
  }
}

.icon {
  margin-right: 5px !important;
  font-size: 14px !important;
}

.input {
  margin-top: 1rem !important;
  text-align: center !important;
  color: #513e2a !important;
  border: none !important;
  background: #efefef !important;

  ::placeholder {
    color: #513e2a !important;
  }

  &__disabled {
    color: #9c9c9c !important;

    &::placeholder {
      color: #9c9c9c !important;
    }
  }

  &__prefix {
    width: 70px !important;
  }

  &__code {
    width: 150px !important;
  }
}
