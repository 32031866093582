$green: #70ad47;
$yellow: #facc2c;
$orange: #fda451;
$red: #da0b0b;
$midgray: #3b4351;
$lightgray: #595959;
$darkgray: #2d2b2b;

.container-card-snippet {
  display: flex;
  cursor: pointer;
}

.container-card-snippet + .container-card-snippet {
  margin-left: 1rem;
}

.container-card-vertical {
  cursor: pointer;
}

.container-card-vertical + .container-card-vertical {
  margin-top: 1rem;
}

.card-subtitle {
  width: 195px !important;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25) !important;
}

.info-card {
  justify-content: space-between;
  overflow: visible !important;
  width: 220px;
  background-color: #ffffff !important;
  cursor: pointer;
  transition: all 500ms ease-in-out;
}

.selected {
  &__base {
    background: #e7e6e6 !important;
  }

  &__green {
    background: #70ad4720 !important;
  }

  &__yellow {
    background: #facc2c20 !important;
  }

  &__orange {
    background: #fda45120 !important;
  }

  &__red {
    background: #da0b0b20 !important;
  }

  &__midgray {
    background: #3b435120 !important;
  }

  &__lightgray {
    background: #59595920 !important;
  }

  &__darkgray {
    background: #2d2b2b20 !important;
  }

  &__blue {
    background: #0070c020 !important;
  }

  &__purple {
    background: #7030a020 !important;
  }
}

.info-text-card {
  display: block;
  width: 80%;
  font-size: 16px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  align-items: center;
  margin: 0 !important;
}

.text-subtitle {
  text-align: center !important;
  font-size: 16px !important;
  color: #000 !important;
  margin: 0 !important;
  font-weight: 400 !important;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.content-card {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 5px 5px 5px 10px;
  min-height: 74.5px;

  &__column {
    padding: 10px !important;
  }

  &__row {
    padding: 10px !important;
  }

  p {
    font-weight: bold;
  }
}

.button-custom,
.tooltip-custom {
  display: flex;
  align-items: flex-start !important;
}

.button-custom {
  font-size: 16px !important;
  width: 16px;
  cursor: pointer;
}

.image-preview {
  width: 100%;
  height: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  margin-right: 1rem;

  &__column {
    width: 180px;
    height: 180px;
    object-fit: cover;
    margin-bottom: 0.5rem;
  }

  &__row {
    width: 75px;
    height: 75px;
    object-fit: cover;
  }

  &__subtitle {
    width: 120px;
    height: 120px;
    object-fit: cover;
    margin-bottom: 0.5rem;
  }
}

.content {
  width: 100%;
  display: flex;
  gap: 10px;
}

.content-bottomLine {
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 80px;
}

.actions {
  display: flex;
  width: 20%;
  align-content: space-between;
  justify-content: flex-end;
  gap: 0.5rem;
}

.content-column {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 10px;

  .info-text-card {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.content-subtitle {
  height: 108px !important;
}

.limited-text-column {
  max-width: 15ch !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.limited-text-row {
  max-width: 12ch !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.text-align-bottomLine {
  height: 50px;
  display: flex;
  align-items: center;
}

.actions-row {
  display: flex;
  width: initial;
  align-content: space-between;
  justify-content: flex-end;
}

.no-edit {
  width: auto !important;
}

.subtitle {
  font-weight: inherit !important;
  display: block;
}

.text-bottomLine {
  width: auto !important;
  text-align: center;
}

.image-reverse-line {
  flex-direction: column;
  min-height: 138px;
}

.limited-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.titleTooltipColumn {
  width: 100%;
  display: flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
}

.titleTooltipRow {
  width: 100%;
  display: flex;
  align-items: center;
}
