@import '../../../variables';

.breadcrumb {
  background-color: rgba($primary, 0.9) !important;

  :global {
    a {
      text-decoration: none;
    }

    a:link,
    a:visited {
      color: #fff;
    }
  }
}

.breadcrumb-round {
  border-bottom-left-radius: 50px;
}

.breadcrumb-link {
  cursor: pointer;
}

.breadcrumb-first {
  margin-left: 15px;
}

.breadcrumb-ellipse {
  overflow: hidden;
  max-width: 60ch;
  text-overflow: ellipsis;
  white-space: nowrap;
}
