/* The Modal (background) */
.modal {
  display: flex;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100vw;
  /* Full width */
  height: 100vh;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  border-radius: 5px;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
  display: grid;
  overflow-y: auto;
  grid-template-columns: 1fr;
  grid-template-rows: 70px 1fr;
}

/* The Close Button */
.close {
  display: flex;
  justify-content: flex-start;
  color: #2b3b47;
  font-size: 28px;
  font-weight: bold;
  font-size: 14px !important;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  font-size: 14px !important;
}

.header-modal {
  display: flex;
  justify-content: space-between;
}
