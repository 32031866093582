.dropdown {
  position: relative;

  .dropdown-btn {
    border-radius: 50px;
    height: 29.14px;
    background-color: #dcdcdc;
    font-family: Roboto, sans-serif;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: revert;
    white-space: nowrap;
    min-width: 150px;
    cursor: pointer;
    padding: 0 6px 0 3px;
    color: #513e2a !important;

    .dropdown-img {
      width: 24px;
      height: 24px;
      border-radius: 50%;
    }

    svg {
      margin-bottom: 6px !important;
    }
  }

  .dropdown-content {
    position: absolute;
    width: 100%;
    background-color: #dcdcdc;
    top: 110%;
    border-radius: 8px;
    z-index: 1;

    .dropdown-item {
      padding: 6px 16px;
      cursor: pointer;
      transition: all 500ms ease-in-out;
      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: flex-start;
      white-space: nowrap;

      &:hover {
        background-color: #c8c8c8;
      }

      .dropdown-img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
      }
    }
  }
}

.text-dropdown {
  width: 94.85px;
  text-align: center;
  color: #513e2a !important;
}

.svg-inline--fa.fa-sort-down.fa-w-10.brass-icon {
  margin-bottom: 6px !important;
}
