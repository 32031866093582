@import '../../../../../variables';

.text-area {
  background: #dcd8d4 !important;
  font-size: 12px !important;
}

.div-messages-info {
  color: rgba($primary, 0.5);
  font-size: 12px;
  font-style: normal;
  text-align: end;
  width: 100%;
}

.div-messages-error {
  margin-top: 0 !important;
  width: 100%;
}

.label {
  font-size: 11px !important;
}

.icon {
  margin-right: 10px;
  display: flex;
  align-items: center;
  font-size: 14px !important;
}
