.content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.input {
  border: none !important;
  width: 50vw !important;
}

.button {
  border: none !important;
  box-shadow: 1px 1px 4px rgba(65, 65, 65, 0.25) !important;
  background: #fff !important;
  color: #404040 !important;
  height: 30px;

  svg {
    font-size: 14px;
  }
}
