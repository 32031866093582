@import '../../../../../variables';

.div-messages-info {
  color: rgba($primary, 0.5);
  font-size: 0.65vw;
  font-style: normal;
  text-align: end;
  width: 100%;
}

.icon {
  margin-right: 10px;
  display: flex;
  align-items: center;
  font-size: 12px;
  width: 12px;
}

.label {
  align-items: center !important;
  font-size: 12px;
}

.input {
  height: 30px;
  font-size: 12px;
  padding: 8px 10px;

  &:disabled {
    background-color: #cccccc;
    pointer-events: none;
    border-bottom: 1px dashed #929292;
    color: #00000087;
  }
}
