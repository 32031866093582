$text-color: #6d6d6d;
$orange: #f6873c;
$orange-75: #f6863cad;
$green: #70ad47;

.chat-block {
  display: flex;
  background-color: #ffff;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  &:nth-child(2) {
    margin-top: 16px;
  }

  .chat-block-user {
    width: 100%;
    margin: 8px 0;
    font-weight: bold;
    font-size: clamp(8px, 0.8vw, 13px);
    line-height: 12px;
    color: $text-color;
    text-align: end;
  }

  .chat-block-description {
    width: 100%;
    margin: 8px 0;
    font-size: clamp(11px, 0.8vw, 16px);
    line-height: 14px;
    color: $text-color;
    text-align: end;
    font-weight: 300;
  }

  .chat-block-actions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .chat-block-actions-counts {
      line-height: 12px;
      font-size: clamp(8px, 0.8vw, 13px);
      color: $text-color;
      font-size: 12px;
      font-weight: 500;
    }
  }
}

.icon {
  width: 20px;
  height: 20px;
  color: $green;
  fill: $green;
  margin: 4px 4px;
  cursor: pointer;
}

.tooltip {
  display: flex;
}

.avatar {
  margin-left: -4px;
  color: #3a3a3a !important;
  border: 0.5px solid #9d9d9d;
  background-image: linear-gradient(43deg, #a7a7a7 0%, #b3afaf 46%, #dadada 100%);
}

.count {
  margin-right: 10px;
  color: #3a3a3a;
}

.box-avatar {
  display: flex;
  margin-right: 15px;
}
