.footer {
  width: 100%;
  margin-top: 20px;
}

.progress {
  height: 0.8vw;
  width: 100%;
  background: #dfdfdf;
  border-radius: 8px;
  padding-block: 3px;
  padding-inline: 2px;

  span {
    font-size: 0.9vw;
  }
}
