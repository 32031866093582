@import './variables';
@import '../../../node_modules/@progress/kendo-theme-material/dist/all';

:root {
  --orange-system: #f6873c;
}

body {
  background-color: #f8f8f8 !important;
  border: 0;
  font-family: Roboto, sans-serif;
  margin: 0;
  overflow: hidden !important;
}

.button-general {
  font-size: 12px !important;
  padding: 5px 10px !important;
  border: none !important;
  height: 30px !important;

  svg {
    font-size: 14px !important;
  }
}

.button-icon {
  font-size: 14px !important;
  margin-right: 5px;
}

.brass-grid {
  svg {
    height: 16px;
    width: 16px;
    font-size: 16px;
  }
}

.k-animation-container.k-animation-container-relative.k-animation-container-shown {
  z-index: 10006 !important;
}

.error-format {
  font-size: 12px;
}

.custom-label {
  opacity: 0.6;
  display: flex;
  margin-bottom: 8px;
}

.button-redirect {
  font-size: 12px !important;
  border: none !important;
  padding: 5px 10px !important;
  height: 30px !important;
}

.brass-button-save,
.button-redirect-map {
  font-size: 12px !important;
  padding: 5px 10px !important;
  border: none !important;
  height: 30px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  letter-spacing: 1.25px !important;
  text-transform: uppercase !important;
}

button[title='Toggle calendar'] {
  &:hover {
    color: white;
    border: none;
  }
}

.titulogrid {
  background-color: #e7e6e6 !important;
  border: none !important;
  padding: 8px 8px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  justify-content: space-between;
  align-items: 'center';
  width: 100%;
  vertical-align: middle;
}

.gridareaintern {
  height: 100%;
}

.gridareaextern {
  margin: 12px;
  border: 1px solid #e7e6e6;
  height: 98%;
}