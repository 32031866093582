.brass-tab-container {
  :global {
    .k-content {
      padding: 0 !important;
    }

    .k-tabstrip-items .k-link {
      padding: 4px 15px !important;
    }
  }
}

.list-view {
  border-bottom: none !important;
}
