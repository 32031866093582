@import '../../../../variables.scss';

.brass-plitter-spliter {
  border: none !important;
  height: calc(100vh - 63px) !important;

  :global {
    .k-splitbar {
      background-color: #424242;
    }

    .k-scrollable {
      overflow: hidden !important;
    }
    .k-splitter-flex {
      height: 100%;
    }
  }
}

.brass-plitter-spliter-loading {
  height: 100%;
  :global {
    .k-splitbar {
      background-color: transparent !important;
    }
    .k-splitter-flex {
      height: 100%;
    }
  }
}
