.form {
  height: calc(100vh - 100px);
  overflow-y: auto;
}

.form-element {
  height: 79vh;
  margin: 5vh auto;
  width: 400px;
}

.action-button {
  display: flex;
  margin-top: 20px;
}

.button-cancel {
  margin-left: 10px;
}

.passwordField {
  width: 350px !important;
}
