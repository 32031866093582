.head-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #cac5bf;
  color: #513e2a;
  padding-block: 5px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 16px;
  min-height: 30px;
}

.box-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}
