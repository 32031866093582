$text-color: #3a3a3a;
$orange: #f6873c;
$orange-75: #f6863cad;
$green: #70ad47;

.chat-block-input {
  width: 100%;
  display: flex;
  margin: 8px 0;

  .chat-block-edit-input {
    width: calc(100% - 30px);
    min-width: 350px;
    height: 24px;
    border: none;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    background-color: #fbfbfb;
    padding: 0 12px;
    font-size: clamp(6px, 0.8vw, 11px);
  }

  .chat-block-edit-button {
    width: 30px;
    height: 24px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    cursor: pointer;

    svg {
      color: white;
      width: 10px;
    }
  }
}
