.row {
  display: flex;
  align-items: flex-end;
  width: 100%;
  gap: 20px;

  div {
    width: calc(100% - 60px);
  }

  .image-input {
    width: 30px;
    height: 30px;
    border-radius: 100%;
  }
}

.action-button {
  font-size: 12px !important;
  padding: 5px 10px !important;
  border: none !important;
  height: 30px !important;
}

.button-icon {
  font-size: 14px !important;
  width: 100% !important;
  margin-right: 5px;
}

.row-editor {
  display: flex;
  align-items: flex-end;
  width: 100%;
  gap: 20px;

  .image-input {
    width: 30px;
    height: 30px;
    border-radius: 100%;
  }
}

.row-margin {
  margin: 40px 0;
}

.row-margin:first {
  margin: 0;
}

.editor {
  background: #dcd8d4 !important;
}

.editor-disabled {
  background-color: #cccccc !important;
  color: #cccccc !important;
  pointer-events: none !important;
  border-bottom: 1px dashed #929292 !important;
  color: #00000087 !important;
}
