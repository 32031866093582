@import '../../../../../../variables.scss';

.div-messages-info {
  color: rgba($primary, 0.5);
  font-size: 12px;
  font-style: normal;
  text-align: end;
  width: 100%;
}

.checkbox {
  display: flex;
  align-items: center;
}
