.main-document {
  // bottom: 25px;

  float: right;

  opacity: 0.6;
  right: 45px;
  text-align: right;
}

.main-document-detail {
  bottom: 50px;
}

.empty-div-viewer {
  float: right;
  position: absolute;
  bottom: 70px;
  right: 50px;
  text-align-last: center;
}
