@import '../../../../../variables';

.topbar {
  background-color: $primary !important;
  color: white !important;
  font-family: 'Roboto';
  font-weight: 600;
  padding: 0px !important;

  :global {
    .k-button.k-button-md {
      font-size: 11px;
    }
  }
}

.brasslogo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;

  :global {
    img {
      padding-top: 5px;
    }
  }
}

.helpdesk {
  width: 28px;
  margin-left: 20px;

  path {
    fill: #fff;
  }
}
