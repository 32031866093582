@import '../../../../variables';

.search-input-container {
  background-color: whitesmoke !important;
  height: 30px !important;
  border-radius: 4px;
  display: flex;

  ::placeholder {
    color: rgb(92, 92, 92) !important;
    opacity: 0.5;
  }

  :global {
    .k-textbox {
      background-color: transparent !important;
    }

    .k-input {
      font-size: 12px !important;
      padding: 8px 10px !important
      ;
    }
  }
}

.search-input {
  color: black !important;
  border: none !important;
}

.search-icon {
  float: right;
  margin-right: 8px;
  margin-top: 8px;
  position: relative !important;
  right: 0;
  font-size: 14px !important;
}
