@import '../../../../variables';

.brass-grid {
  height: calc(100vh - 100px);

  :global {
    .k-grid-footer tfoot tr td {
      padding: 0px !important;
    }

    .k-column-title,
    .k-button-text {
      font-size: 11px;
    }

    .k-grid-toolbar {
      border: none !important;
    }

    .k-grid-header th.k-header {
      border-bottom: none !important;
      padding-bottom: 0 !important;
      padding-top: 0 !important;
    }

    .k-filter-row th {
      padding-bottom: 0 !important;
      padding-left: 3px !important;
      padding-right: 3px !important;
      padding-top: 0 !important;
    }

    .k-filter-row {
      border-bottom: 2px !important;
    }

    .k-pager-wrap {
      padding-bottom: 0 !important;
      padding-top: 0 !important;
    }

    .k-grid-pager .k-pager-info {
      margin-right: 30px;
    }

    .k-grid-container .k-grid-table tr.k-master-row td {
      font-size: 12px !important;
      padding: 3px;
    }

    .k-grouping-row td {
      padding-top: 1px;
      padding-bottom: 1px;
      background-color: #e1e1e1;
    }

    .k-grid-header .k-grid-filter,
    .k-grid-header .k-header-column-menu {
      z-index: auto;
    }
  }
}

.brass-grid-button-edit {
  :global {
    svg {
      color: rgba($primary, 0.6) !important;
      font-size: medium;
    }
  }
}

.brass-grid-button-delete {
  :global {
    svg {
      color: rgba($primary, 0.6) !important;
      font-size: medium;
    }
  }
}

.brass-grid-button-edit:hover,
.brass-grid-button-delete:hover {
  background-color: rgba($primary, 0.1) !important;

  :global {
    svg {
      color: rgba($primary, 0.8) !important;
    }
  }
}

.brass-grid-title {
  color: rgba($primary, 0.9) !important;
  margin: 0;
  margin-left: 10px;
  font-size: 16px !important;
  align-items: center;

  :global {
    svg {
      color: rgba($primary, 0.9) !important;
      margin-right: 5px;
    }
  }
}

.brass-grid-column-center {
  text-align: center !important;
}

.brass-grid-brasslogo {
  bottom: 31px;
  float: right;
  margin-right: 3px;
  position: relative;
}

.brass-button-refresh,
.brass-button-insert {
  :global {
    svg {
      margin-top: 2px !important;
    }
  }
}

.brass-button-refresh {
  border: none !important;

  :global {
    .k-button-text span {
      margin-top: 2px !important;
      display: none !important;
    }
  }
}

.brass-button-groupable {
  background-color: transparent !important;
  border: none !important;
  border-radius: 50% !important;
}

.brass-button-export {
  button {
    @extend .brass-button-groupable;
  }
}

.help-user {
  margin: 10px;
}

.treeliststyle {
  overflow: auto;
  max-height: calc(100vh - 100px);
}
