.breadcrumb {
  width: 100%;
  background-color: #3a3a3a;
  height: 30px;
  box-shadow: none !important;
}

.breadcrumb-round {
  border-bottom-left-radius: 50px;
}

.breadcrumb-linkV1 {
  cursor: pointer;
}

.breadcrumb-firstV1 {
  margin-left: 15px;
}
