.container-view-select {
  display: flex;
  width: 100%;
  flex-direction: flex-start;
  gap: 20px;
  margin-top: 8px;

  span svg {
    width: 45px !important;
    height: 60% !important;
  }

  // &:focus { }
}

.container-view-larger {
  display: flex;
  // width: 100%;
  gap: 20px;

  span svg {
    width: 45px !important;
    height: 60% !important;
  }
}

.container-view-medium {
  display: flex;
  // width: 100%;
  gap: 20px;

  span svg {
    width: 45px !important;
    height: 60% !important;
  }
}

.image-preview {
  width: 90%;
  height: 88%;
  padding: 8%;
  object-fit: cover;
}

.large-image-preview {
  width: 90%;
  height: 88%;
  object-fit: cover;
}

.container-image-dotted {
  width: 60px;
  height: 50px;
  background-size: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='black' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.medium-container-image,
.larger-container-image {
  width: 103px;
  height: 97px;
  background-size: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: dashed 2px;
}

.medium-container-image {
  width: 8.2vh;
  height: 8.2vh;
}

.button-shared-image {
  padding: 0 !important;
  margin: 0 !important;
  border: none !important;
}

.label {
  align-items: center !important;
  font-size: 11px !important;

  svg {
    font-size: 14px !important;
  }
}
