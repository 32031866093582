@import '../../../../variables';

.search-input-container {
  background-color: rgba($primary, 0.1) !important;
  border-radius: 4px;
  display: flex;
  margin: 0 20px 0 0;

  ::placeholder {
    color: rgb(92, 92, 92) !important;
    opacity: 0.5;
  }

  :global {
    .k-textbox {
      background-color: transparent !important;
    }
  }
}

.search-input {
  color: black !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.search-icon {
  float: right;
  margin-right: 5px;
  margin-top: 10px;
  position: relative !important;
  right: 0;
}
