@import '../../../../variables.scss';

.navigator-card {
  background-color: rgba(0, 0, 0, 0.05) !important;
  border-radius: 4px !important;

  cursor: pointer;
  height: 154px;

  margin: 15px;
  padding: 0;
  width: 154px;

  :global {
    img {
      margin: auto;
      max-height: 90px !important;
      max-width: 90px !important;
    }
  }
}

.navigator-card:hover {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.navigator-card-loading {
  :global {
    .brass-card-title,
    .brass-card-subtitle {
      color: white !important;
    }
  }
}

.navigator-card-image {
  background-color: white;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 120px;
  width: 154px;
}

.navigator-card-image-with-convert {
  height: 80px;
}

.navigator-card-tag {
  font-size: 10px !important;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  text-align: center;
}

.navigator-card-title {
  color: #3e3e3e !important;
  font-size: 13px !important;
  font-weight: bold !important;
  margin-bottom: 0 !important;
  padding-inline: 4px;
  text-align: center;
  white-space: nowrap;
}

.navigator-card-converting {
  text-align: center;
}

.navigator-card-actions {
  display: flex;
  position: absolute;
  top: 1px;
}

.navigator-card-configure {
  bottom: -12px !important;
  position: absolute !important;
  right: -10px !important;

  :global {
    svg {
      color: rgba($primary, 0.5) !important;
      font-size: 15px;
    }

    svg:hover {
      color: rgba($primary, 0.8) !important;
      font-size: 15px;
    }
  }
}

.navigator-card-configure:hover {
  background-color: rgba($primary, 0.3) !important;
}

.iconInformation {
  position: absolute;
  right: 8px;
  top: 8px;
}
