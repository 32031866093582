@import '../../../../../variables';

.div-messages-info {
  color: rgba($primary, 0.5);
  font-size: 12px;
  font-style: normal;
  text-align: end;
  width: 100%;
}

.brass-datepicker-field-popup {
  z-index: 999999 !important;
}
