@import '../../../../../variables';

.container {
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin: 10px;
  padding: 10px;
  text-align: center;
  transition: 0.3s;
  min-height: 120px;
  min-width: 300px;
  max-width: 480px;
}

.container:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.description {
  font-size: 1rem;
  margin-top: 5px;
}

.data {
  font-size: 30px;
  vertical-align: super;
}

.icon {
  color: $primary;
  margin: 10px 15px 2px 0;
}
