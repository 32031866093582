@import '../../../../variables.scss';

.brass-spliter {
  border: none !important;
  height: calc(100vh - 63px) !important;

  :global {
    .k-splitbar {
      background-color: #424242;
    }

    .k-scrollable {
      overflow: hidden !important;
    }
    .k-splitter-flex {
      height: 100%;
    }
  }
}

.brass-spliter-loading {
  height: 100%;
  :global {
    .k-splitbar {
      background-color: transparent !important;
    }
    .k-splitter-flex {
      height: 100%;
    }
  }
}

.button-upload-document {
  color: #fff;
  background-color: $primaryBrassColor !important;
  z-index: 9999;
}

.button-asset-management {
  color: #fff;
  background-color: $primaryBrassColor !important;
  z-index: 9999;
}

.button-document-informations {
  margin-bottom: 150px;
  margin-right: 28px;
  color: #fff;
  background-color: $primaryBrassColor !important;
  z-index: 9999;
}

.button-document-sharing {
  margin-bottom: 230px;
  margin-right: 28px;
  color: #fff;
  background-color: $primaryBrassColor !important;
  z-index: 9999;
}

.button-document-sharing-detail {
  margin-bottom: 455px;
}

.button-informations-document-detail {
  margin-bottom: 370px;
}

.button-upload-document-detail {
  margin-bottom: 290px;
}

.point-document-details-viewer-on {
  height: calc(100vh - 300px);
  width: 100%;
}

.point-document-viewer {
  height: calc(100vh - 125px);
  width: 100%;
}

.buttonBind {
  margin: 80px 10px;
}

.buttonSurvey {
  margin: 10px;
}

.documentViewerDiv {
  width: 100%;
  text-align: center;
}

.floatPointViewer {
  display: flex;
  justify-content: right;
}

.floatPointViewerOn {
  width: calc(100vw - 500px);
  float: left;
  display: flex;
  justify-content: right;
}
