.document-details-viewer-on {
  height: calc(100vh - 300px);
}

.document-viewer {
  height: calc(100vh - 125px);
}

.progress-viewer {
  width: 100%;
  text-align: center;
}
