.carousel {
  height: 100%;
  display: flex;
  margin: 0 auto;
  width: 100%;
  padding-block: 10px;
  position: relative;
  box-sizing: border-box;
  scroll-snap-type: x mandatory;
  --webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  scrollbar-width: none;
  justify-content: space-between;
  overflow-x: hidden;
}

.carousel-button-left {
  position: sticky;
  left: 0px;
  height: 100%;
  z-index: 9000;
  transition: all ease-in 200ms;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ededed;
  cursor: pointer;

  svg {
    color: #424242 !important;
    font-size: 20px;
  }

  &:hover {
    background-color: #e0e0e0;
  }
}

.box-right {
  position: sticky;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.carousel-button-right {
  position: sticky;
  right: 0;
  height: 100%;
  z-index: 9000;
  transition: all ease-in 200ms;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ededed;
  cursor: pointer;

  svg {
    color: #424242 !important;
    font-size: 20px;
  }

  &:hover {
    background-color: #e0e0e0;
  }
}

.list {
  // display: flex;
  // justify-content: flex-start;
  // align-content: flex-start;
  gap: 15px;
  padding: 5px 10px;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
}
