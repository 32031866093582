.download-window {
  position: fixed;
  border: 1px solid #ccc;
  background-color: #f6f7f8;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  width: 400px;
  font-family: sans-serif;
  font-size: 14px;
  z-index: 100000000;

  .download-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #242424;
    color: #f6f7f8;
    padding: 10px;
    border-bottom: 1px solid #ccc;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    .download-title {
      font-weight: bold;
      font-size: 14px;
    }

    .download-close {
      cursor: pointer;
      font-size: 16px;
      background-color: transparent;
      color: white;
    }
  }

  .download-body {
    padding: 10px 20px;

    .box-item-list {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      padding: 10px 0;
      border-bottom: 1px solid black;

      .content-description {
        width: calc(100% - 60px);

        .download-filename {
          font-weight: bold;
        }

        .download-size {
          margin-top: 5px;
          color: #777;
        }
      }

      .download-progress {
        .download-progress-bar {
          height: 100%;
          width: 0;
          background-color: #4285f4;
          transition: width 0.5s ease-in-out;
        }
      }

      &:nth-last-child(1) {
        border: none;
      }
    }
  }
}
