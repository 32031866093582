@import '../../../../variables';

.container {
  display: flex;
  margin: 0 auto;
  overflow-x: auto;
}

.card {
  flex: 1 0 25%;
  flex-grow: 1;
}

.card-on {
  background-color: #c2dbff;
}
