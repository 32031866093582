.alert {
  background: white;
  border-radius: 50%;
  height: 30px;
  width: 30px;

  box-shadow: 0 0 0 0 rgba(255, 255, 255, 1) !important;
  transform: scale(1);
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7) !important;
  }

  60% {
    transform: scale(1.1);
    box-shadow: 0 0 0 30px rgba(255, 255, 255, 0) !important;
  }

  100% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0) !important;
  }
}

.helpdesk {
  width: 28px;
  display: flex;

  path {
    fill: #fff;
  }
}
