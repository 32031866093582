.list {
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  padding: 1%;
  overflow-y: auto;
}

.flexColumn {
  display: flex;
  padding: 1%;
  align-items: center;
  flex-direction: column;
}

.no-data {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 3rem;
  font-size: 12px;
}

.no-data-vertical {
  margin: auto;
  justify-content: center;
  font-size: 12px;
}
