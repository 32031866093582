.header-action {
  display: flex;
}

.action-space {
  margin-right: 10px;
}

.search-box {
  display: flex;
  margin: 0 30px 0 0;
}

.search-box-icon {
  margin: 10px 0 0 -30px;
}

.title {
  font-size: 16px;
  margin-left: 15px;
}
