@import '../../../../../variables';

.text-area {
  background-color: rgba($primary, 0.1) !important;
}

.div-messages-info {
  color: rgba($primary, 0.5);
  font-size: 12px;
  font-style: normal;
  text-align: end;
  width: 100%;
}

.div-messages-error {
  margin-top: 0 !important;
  width: 100%;
}
