@import '../../../variables';

.brass-button-secundary {
  background-color: rgba($primary, 0.2) !important;
}

.brass-button-secundary:hover {
  background-color: $primary !important;
  color: #fff !important;
}

.brass-button-round {
  border-radius: 100px !important;
  height: 48px !important;
  width: 48px !important;
}
