.arrowIcon {
  :global {
    .k-menu-expand-arrow {
      display: none;
    }
  }
}

.button {
  width: 100%;
  cursor: pointer;
  background: none;
  border: none;
  text-align: left;

  span {
    margin-right: 1rem;
  }

  svg {
    margin-right: 1rem;
  }
}
