.circular-progress-bar {
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.2s ease;

  &::before {
    content: '';
    position: absolute;
    height: 90%;
    width: 90%;
    border-radius: 50%;
  }

  .value-progress-bar {
    color: #020202;
    font-size: 12px;
    width: 90%;
    height: 90%;
    border-radius: 100%;
    background: whitesmoke;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
