.icon {
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.margin {
  margin-top: 1em;
}

.label {
  font-size: 11px;
}

:global {
  .k-colorpalette-tile {
    border-radius: 100% !important;
    width: 30px;
    height: 30px;
  }

  .k-colorpalette-table.k-palette tbody tr {
    height: 50px;
    width: calc(100% - 34.5px);
    display: flex !important;
    gap: 8px;
    justify-content: center !important;
    align-items: center !important;
    margin-left: 34.5px;
  }

  .k-colorpalette-tile.k-state-selected,
  .k-colorpalette-tile.k-state-selected:hover {
    -webkit-box-shadow: 0px 0px 13px 0px #4d4a4a !important;
    box-shadow: 0px 0px 5px 0px #4d4a4a !important;
  }
}
