.container {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #c00000;
}

.box-in {
  border: 3px solid #c00000;
  padding-inline: 25px;
  border-radius: 15px;
}

.title {
  font-size: 45px;
}

@media (min-width: 1240px) and (max-width: 1360px) {
  .title {
    font-size: 40px;
  }
}

@media (min-width: 1366px) and (max-width: 1500px) {
  .title {
    font-size: 40px;
  }
}

@media (min-width: 1501px) and (max-width: 1520px) {
}

@media (min-width: 1700px) and (max-width: 1800px) {
}

@media screen and (min-width: 1920px) {
}
