.password {
  padding-top: 15px;
  width: 100vw;
}

.container {
  display: flex;
}

.button-eye {
  align-self: flex-end;
}
