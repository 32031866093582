.div-fields-tag-costcenter {
  display: flex;
}

.div-fields-tag-name-file-classification {
  display: flex;
}

.div-fields-divider {
  margin-right: 30px;
}
