.module-title {
  font-size: medium;
  font-weight: 400;
  margin-left: 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    path {
      fill: white;
    }

    margin-right: 10px;
  }
}
