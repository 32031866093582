.date-range-picker {
  width: auto !important;

  :global {
    .k-daterangepicker-wrap {
      display: flex !important;
    }

    .k-dateinput {
      width: 100px !important;
      font-size: 12px;
    }
  }
}
