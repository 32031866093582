:global {
  .brass-background {
    z-index: 1;
  }
}

.dropzone {
  padding: 20px;
  border: 2px dashed orange;
}

.brass-uploader :global {
  .k-actions {
    height: 0;
    visibility: hidden;
  }
}

.brass-dialog-uploader :global {
  .k-external-dropzone {
    height: calc(100vh - 428px);
  }
}

.information-upload-div {
  background-color: #0073bb;
  color: #fff;
  display: flex;
  margin-top: 5px;
  align-items: center;
  justify-content: center;
}

.information-upload-left-div {
  float: left;
  margin: 0;
}

.information-upload-right-div {
  float: right;
  width: 98%;
}

.brass-dialog-uploader-with-files :global {
  background-color: rgba(0, 0, 0, 0.589);

  .k-external-dropzone {
    height: calc(100vh - 737px);
  }

  .k-upload-files {
    height: 100vh;
  }
}

.brass-dialog-uploader-uploading :global {
  .k-external-dropzone {
    height: 0;
    visibility: hidden;
  }

  .k-upload-button {
    height: 0;
    visibility: hidden;
  }

  .k-window-actions.k-dialog-actions {
    visibility: hidden;
  }
}

.upload-button {
  border: none !important;
  width: 100%;
  padding: 0;
  margin: 20px 0;

  div div + div {
    display: none !important;
  }

  :global {
    .k-file-name-size-wrapper {
      width: 800px;
    }
  }
}

.container-select-save {
  display: flex;
  height: 10%;
  width: 100%;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
}

.brass-close {
  background-color: transparent;
  color: black;
  border: 1px solid black;
  height: 30px;
  box-shadow: none;
}

.brass-action-bar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-content: center;
}
