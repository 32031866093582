.default {
  text-align: center;
}

.weak {
  background-color: red;
  border-radius: 4px;
  color: white;
  margin: 10px 0;
  text-align: center;
}

.medium {
  background-color: blue;
  border-radius: 4px;
  color: white;
  margin: 10px 0;
  text-align: center;
}

.strong {
  background-color: green;
  border-radius: 4px;
  color: white;
  margin: 10px 0;
  text-align: center;
}

.differentPasswords {
  background-color: #e5b700;
  border-radius: 4px;
  color: white;
  margin: 10px 0;
  text-align: center;
}
